import { VideoComponentDataRowSubTypeEnums } from "../universal/components/RowComponents/VideoRow/constants";
import { BannerComponentDataRowSubTypeEnums } from "../universal/components/RowComponents/BannerRow/contants";

export const DAVINCI_PAGE_MERCHANT = "Merchant Page";
export const DAVINCI_PAGE_ORDER = "Order Page";
export const ALLOWED_QUERY_PARAMS = ["merchantId", "merchantName", "totalProductCount", "h1title", "preview"];

export const ALLOWED_SEARCH_PARAMS = ["filtreler", "q", "kategori", "puan", "markalar", "sayfa", "siralama"];

export const LAYOUT_COMPONENTS = {
  "Çok Satanlar": {
    name: "MostSalesProducts",
    title: "Çok Satanlar",
    queryParam: {
      key: "siralama",
      value: "coksatan"
    },
    orderByIndex: 0
  },
  "4 ve 4+ Puanlı Ürünler": {
    name: "FourPointsProducts",
    title: "4 ve 4+ Puanlı Ürünler",
    queryParam: {
      key: "puan",
      value: "4-max"
    },
    orderByIndex: 1
  },
  "İndirimli Ürünler": {
    name: "DiscountedProducts",
    title: "İndirimli Ürünler",
    queryParam: {
      key: "filtreler",
      value: "VariantList.VariantListing.DiscountRate:1-100"
    },
    orderByIndex: 2
  },
  "En Çok Değerlendirilenler": {
    name: "MostReviewedProducts",
    title: "En Çok Değerlendirilenler",
    queryParam: {
      key: "siralama",
      value: "yorumsayisi"
    }
  },
  orderByIndex: 3
};

export const ORDER_LAYOUT_COMPONENTS = [
  LAYOUT_COMPONENTS["Çok Satanlar"],
  LAYOUT_COMPONENTS["4 ve 4+ Puanlı Ürünler"],
  LAYOUT_COMPONENTS["İndirimli Ürünler"],
  LAYOUT_COMPONENTS["En Çok Değerlendirilenler"]
];

export const HB_ADULT = "1";

export const AdultEnums = {
  attributeName: "+18 Ürün",
  attributeValue: true
};

export const AB_TESTING_PARAMS = {
  PERSONALIZED_READY_PRODUCTS: {
    EXPERIMENT_KEY: "PersonalizedReadyProductsList",
    BUCKETS: {
      PersonalizedList: "PersonalizedList",
      Control: "Control"
    }
  }
};

export const COLORS = {
  cokrenk: "linear-gradient(135deg, #e02020, #fa6400 17%, #f7b500 33%, #6dd400 50%, #0091ff 67%, #6236ff 83%, #b620e0)",
  beyaz: "#ffffff",
  kirmizi: "#ed242c",
  siyah: "#111111",
  mavi: "#53bfe6",
  mor: "#7622e5",
  gri: "#9b9b9b",
  gumus: "#e5e5e5",
  kahverengi: "#690000",
  krem: "#ffccaf",
  bej: "#FFECE0",
  lacivert: "#2d3769",
  yesil: "#5ad363",
  sari: "#f3a027",
  turuncu: "#ff6000",
  pembe: "#fb6f6f"
};

export const CONTENT_ENABLE_BRAND_FEATURES = "content-enable-brand-features";
export const CONTENT_DISABLE_CUSTOM_BANNER = "content-disable-custom-banner";
export const FIRSAT_CADIRI_MERCHANT_ID = "53b157d2-1028-417b-8b16-a72798f9e05d";

export const SUPER_TAB_USERS = ["b7f76041-24f4-49ef-b9b2-19ae5c7a8c02", "68869c0f-db7c-4f86-9732-cbd9b37ed747"];

// In the beginning where this array is used, all rows are added initially.
// Then, if tag exist in merchant data, necessary components will be deleted or leave it as it is.
// @params {willBeDeleted} is used for that purpose. If component has to be deleted when tag is exist, value of {willBeDeleted} will be TRUE
export const SPECIAL_COMPONENTS_WITH_MERCHANT_TAGS = [
  {
    tag: CONTENT_ENABLE_BRAND_FEATURES,
    willBeDelete: false,
    componentSubTypeList: [VideoComponentDataRowSubTypeEnums.DETAIL]
  },
  {
    tag: CONTENT_DISABLE_CUSTOM_BANNER,
    willBeDelete: true,
    componentSubTypeList: [BannerComponentDataRowSubTypeEnums.CUSTOM_BANNER]
  }
];
